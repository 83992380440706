import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { TasksModule } from './tasks/tasks.module';
import { DivisionsModule } from './divisions/divisions.module';
import { EmployeesModule } from './employees/employees.module';
import { PartnersModule } from './partners/partners.module';
import { EnumerationModule } from './enumeration/enumeration.module';
import { InsuranceCompanyModule } from './insurance-company/insurance-company.module';
import { TasksFileModule } from './taks-file/tasks-file.module';
import { TaskCalculationFileModule } from './task-calculation-file/task-calculation-file.module';
import { TaskFileGroupModule } from './task-file-group/task-file-group.module';
import { ProfileModule } from './profile/profile.module';
import { TasksInternalFileModule } from './taks-internal-file/tasks-internal-file.module';
import { PartnerFileModule } from './partner-file/partner-file.module';
import { CarTirePositionsModule } from './car-tire-positions/car-tire-positions.module';
import { CarPartTypesModule } from './car-part-types/car-part-types.module';
import { TaskHasCarPartRepairsModule } from './task-has-car-part-repairs/task-has-car-part-repairs.module';
import { GetPartnerTagsModule } from './partner-tag/get-partner-tags.module';
import { TaskTagModule } from './task-tag/task-tag.module';

@NgModule({
  imports: [CommonModule],
  exports: [
    AuthModule,
    CarPartTypesModule,
    CarTirePositionsModule,
    DivisionsModule,
    EmployeesModule,
    PartnersModule,
    ProfileModule,
    PartnerFileModule,
    EnumerationModule,
    InsuranceCompanyModule,
    TasksModule,
    TaskCalculationFileModule,
    TasksFileModule,
    TasksInternalFileModule,
    TaskFileGroupModule,
    TaskTagModule,
    TaskHasCarPartRepairsModule,
    GetPartnerTagsModule,
  ],
})
export class GraphqlModule {}
