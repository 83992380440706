import { Injectable } from '@angular/core';
import { DivisionListService } from '../graphql/divisions/division-list.service';
import { Observable } from 'rxjs';
import { DivisionCreateService } from '../graphql/divisions/create.service';
import {
  DivisionUpdatePatch,
  DivisionUpdateService,
} from '../graphql/divisions/update.service';
import { DivisionByIdService } from '../graphql/divisions/division-by-id.service';
import { AddPartnerToDivisionService } from '../graphql/divisions/add-partner-to-division.service';
import { AddPartnerToEmployeeService } from '../graphql/divisions/add-partner-to-employee.service';
import { RemovePartnerFromEmployeeService } from '../graphql/divisions/remove-partner-from-employee.service';
import { AddEmployeesToDivisionService } from '../graphql/divisions/add-employees-to-division.service';
import { DocumentNode } from 'graphql';
import { map } from 'rxjs/operators';

@Injectable()
export class DivisionsService {
  constructor(
    private addPartnerToEmployeeService: AddPartnerToEmployeeService,
    private addEmployeesToDivisionService: AddEmployeesToDivisionService,
    private removePartnerFromEmployeeService: RemovePartnerFromEmployeeService,
    private divisionListService: DivisionListService,
    private divisionCreateService: DivisionCreateService,
    private addPartnerToDivisionService: AddPartnerToDivisionService,
    private divisionByIdService: DivisionByIdService,
    private divisionUpdateService: DivisionUpdateService
  ) {}

  addPartnerToDivision(
    partnerId: string,
    divisionId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.addPartnerToDivisionService
      .addPartnerToDivision(partnerId, divisionId, mutation)
      .toPromise();
  }

  addEmployeesToDivision(
    divisionId: string,
    employeeIds: Array<string>,
    mutation: DocumentNode
  ): Promise<any> {
    return this.addEmployeesToDivisionService
      .addEmployeesToDivision(divisionId, employeeIds, mutation)
      .toPromise();
  }

  addPartnerToEmployee(
    partnerId: string,
    employeeId: string,
    divisionId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.addPartnerToEmployeeService
      .addPartnerToEmployee(partnerId, employeeId, divisionId, mutation)
      .toPromise();
  }

  removePartnerFromEmployee(
    partnerId: string,
    employeeId: string,
    divisionId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.removePartnerFromEmployeeService
      .removePartnerFromEmployee(partnerId, employeeId, divisionId, mutation)
      .toPromise();
  }

  getDivisions(query: DocumentNode): Observable<Array<any>> {
    return this.divisionListService
      .getDivisions(query)
      .valueChanges.pipe(
        map((divisionData) => divisionData.data.division.list.output)
      );
  }

  getDivisionById(divisionId: string, query?: DocumentNode): Observable<any> {
    return this.divisionByIdService
      .getDivisionById(divisionId, query)
      .valueChanges.pipe(
        map((divisionData) => divisionData.data.division.byId.output)
      );
  }

  createDivision(
    name: string,
    damageCategoryId: string,
    damageTypeId: string,
    mutation: DocumentNode
  ): Promise<any> {
    return this.divisionCreateService
      .createDivision(name, damageCategoryId, damageTypeId, mutation)
      .toPromise();
  }

  updateDivision(
    id: string,
    patch: DivisionUpdatePatch,
    mutation: DocumentNode
  ): Promise<any> {
    return this.divisionUpdateService
      .updateDivision(id, patch, mutation)
      .toPromise();
  }
}
