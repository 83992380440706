import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthApiV2Module } from './auth/auth-api-v2.module';
import { CentralEventsModule } from './central-events/central-events.module';
import { DamageEventsModule } from './damage-events/damage-events.module';
import { TaskReserveModule } from './task-reserve/task-reserve.module';

@NgModule({
  imports: [CommonModule],
  exports: [
    AuthApiV2Module,
    CentralEventsModule,
    DamageEventsModule,
    TaskReserveModule
  ],
})
export class RestModule {}
