import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { SetFileGroupResponse } from './definitions/set-file-group-response-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class SetFileGroupService {
  constructor(private apollo: Apollo) {}

  setFileGroup(
    fileId: string,
    taskFileGroupId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<SetFileGroupResponse>> {
    return this.apollo.mutate<SetFileGroupResponse>({
      mutation: mutation,
      variables: {
        fileId: fileId,
        taskFileGroupId: taskFileGroupId,
      },
    });
  }
}
