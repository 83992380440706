import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DivisionInterface } from './definitions/division-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface RemovePartnerFromEmployeeResponse {
  division: {
    removePartnerFromEmployee: {
      output: DivisionInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class RemovePartnerFromEmployeeService {
  constructor(private apollo: Apollo) {}

  removePartnerFromEmployee(
    partnerId: string,
    employeeId: string,
    divisionId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<RemovePartnerFromEmployeeResponse>> {
    return this.apollo.mutate<RemovePartnerFromEmployeeResponse>({
      mutation: mutation,
      variables: {
        partnerId: partnerId,
        employeeId: employeeId,
        divisionId: divisionId,
      },
    });
  }
}
