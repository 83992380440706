import { Apollo } from 'apollo-angular';
import { PartnerInterface } from './definitions/partner-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';

export interface PartnerCustomTaskCompletedEmailingSubjectResponse {
  partner: {
    customTaskCompletedSubject: {
      output: PartnerInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class PartnerCustomTaskCompletedEmailingSubjectService {
  constructor(private apollo: Apollo) {}

  public update(
    id: string,
    subject: string,
    mutation: DocumentNode
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<PartnerCustomTaskCompletedEmailingSubjectResponse>({
      mutation: mutation,
      variables: {
        id: id,
        subject: subject,
      },
    });
  }
}
