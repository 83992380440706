import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TagInterface } from './definitions/tag-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface TagCreateResponse {
  Tag: {
    create: {
      output: Array<TagInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TagCreateService {
  constructor(private apollo: Apollo) {}

  createTag(
    tag: TagInterface,
    mutation: DocumentNode
  ): Observable<FetchResult<TagCreateResponse>> {
    return this.apollo.mutate<TagCreateResponse>({
      mutation: mutation,
      variables: {
        text: tag.text,
        color: tag.color,
      },
    });
  }
}
