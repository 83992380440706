import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadInternalTaskFileService } from './upload-internal-task-file.service';
import { RemoveInternalTaskFileService } from './remove-internal-task-file.service';

@NgModule({
  imports: [CommonModule],
  providers: [UploadInternalTaskFileService, RemoveInternalTaskFileService],
})
export class TasksInternalFileModule {}
