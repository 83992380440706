import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface RemoveTaskFileGroupResponse {
  taskFileGroup: {
    removePermanently: {
      output: { id: string };
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class RemoveTaskFileGroupService {
  constructor(private apollo: Apollo) {}

  remove(
    taskFileGroupId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<RemoveTaskFileGroupResponse>> {
    return this.apollo.mutate<RemoveTaskFileGroupResponse>({
      mutation: mutation,
      variables: {
        id: taskFileGroupId,
      },
    });
  }
}
