import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskInternalFileInterface } from './definitions/task-internal-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { taskDetailQuery } from '../../../../admin/task-detail/task-detail.data';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';
import { cloneDeep } from '@apollo/client/utilities';

interface InternalFileUploadResponse {
  taskInternalFile: {
    upload: {
      output: TaskInternalFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class UploadInternalTaskFileService {
  constructor(private apollo: Apollo) {}

  uploadFile(
    taskId: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode,
    customPlaceHolderId?: string
  ): Observable<FetchResult<InternalFileUploadResponse>> {
    return this.apollo.mutate<InternalFileUploadResponse>({
      mutation: mutation,
      variables: {
        taskId: taskId,
        name: name,
        content: btoa(content),
        extension: extension,
      },
      optimisticResponse: {
        taskInternalFile: {
          upload: {
            output: {
              id: customPlaceHolderId
                ? customPlaceHolderId
                : '-' + Math.random(),
              task: null,
              size: 0,
              createdAt: new Date(),
              updatedAt: new Date(),
              removedAt: null,
              type: null,
              src: null,
              thumbnails: [],
              taskId: taskId,
              name: name,
              extension: extension,
            },
            errors: {
              errors: [],
              status: 0,
              message: '',
            },
          },
        },
      },
      update: (proxy, mutationResult) => {
        let data = <{ task: any }>(
          proxy.readQuery({ query: taskDetailQuery, variables: { id: taskId } })
        );
        if (
          mutationResult.data.taskInternalFile.upload.output !== undefined &&
          mutationResult.data.taskInternalFile.upload.output !== null
        ) {
          data = cloneDeep(data);
          data.task.byId.output.taskInternalFiles.push(
            mutationResult.data.taskInternalFile.upload.output
          );
        }
        proxy.writeQuery({
          query: taskDetailQuery,
          variables: { id: taskId },
          data,
        });
      },
    });
  }
}
