import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { RemoveProfileImageResponseInterface } from './definitions/remove-profile-image-response-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class RemoveProfileImageService {
  constructor(private apollo: Apollo) {}

  removePermanently(
    mutation: DocumentNode
  ): Observable<FetchResult<RemoveProfileImageResponseInterface>> {
    return this.apollo.mutate<RemoveProfileImageResponseInterface>({
      mutation: mutation,
    });
  }
}
