import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { TaskReserveDetailInterface } from '../definitions/task-reserve-detail-interface';

@Injectable()
export class TaskReserveActualDetailService {

  private apiUrl: string = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public actualDetail(taskId: string): Observable<TaskReserveDetailInterface> {
    const url = `${this.apiUrl}/tasks/${taskId}/reserve`;

    return this.client.get<TaskReserveDetailInterface>(url).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
