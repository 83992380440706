import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class AuthorizeService {

  private apiUrl: string = environment.rest.apiV2Url;

  constructor(private client: HttpClient) {}

  public authorize(username: string, password: string): Observable<any> {
    const url = `${this.apiUrl}/auth/login`;

    return this.client.post(url, { username: username, password: password });
  }
}
