import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApproveTaskFileGroupService } from './approve.service';
import { SetDocumentsMissingService } from './set-documents-missing.service';
import { CreateTaskFileGroupService } from './create.service';
import { ResetTaskFileGroupService } from './reset.service';
import { CancelCompletionTaskFileGroupService } from './cancel-completion.service';
import { RemoveTaskFileGroupService } from './remove.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    ApproveTaskFileGroupService,
    CreateTaskFileGroupService,
    SetDocumentsMissingService,
    ResetTaskFileGroupService,
    CancelCompletionTaskFileGroupService,
    RemoveTaskFileGroupService,
  ],
})
export class TaskFileGroupModule {}
