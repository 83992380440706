import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PartnerInterface } from './definitions/partner-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface PartnerUpdateResponse {
  partner: {
    update: {
      output: PartnerInterface;
      errors: ErrorsInterface;
    };
  };
}

export interface PartnerUpdatePatch {
  type?: string;
  name?: string;
  code?: string;
  location?: string;
  contactEmail?: string;
  defaultAllowOnlineTaskInspection?: boolean;
  allowedOnlineTaskInspection?: boolean;
  partnerTags?: Array<string>;
}

@Injectable()
export class PartnerUpdateService {
  constructor(private apollo: Apollo) {}

  update(
    id: string,
    patch: PartnerUpdatePatch,
    mutation: DocumentNode
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<PartnerUpdateResponse>({
      mutation: mutation,
      variables: {
        id: id,
        name: patch.name,
        type: patch.type,
        code: patch.code,
        location: patch.location,
        contactEmail: patch.contactEmail,
        defaultAllowOnlineTaskInspection:
          patch.defaultAllowOnlineTaskInspection,
        allowedOnlineTaskInspection: patch.allowedOnlineTaskInspection,
        partnerTags: patch.partnerTags,
      },
    });
  }
}
