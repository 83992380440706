import { gql } from 'apollo-angular';
import { authTypeDef } from '../definitions/auth-type-def';
import { errorsTypeDef } from '../../errors/errors-type-def';

const authorizeMutation = gql`
  mutation authorizeEmployee($username: String!, $password: String!) {
    auth {
      authorizeEmployee(input: {
        username: $username
        password: $password
      }) {
        output {
          ${authTypeDef}
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;

export default authorizeMutation;
