import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { DivisionInterface } from './definitions/division-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface DivisionListResponse {
  division: {
    list: {
      output: Array<DivisionInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class DivisionListService {
  constructor(private apollo: Apollo) {}

  getDivisions(query: DocumentNode): QueryRef<DivisionListResponse> {
    return this.apollo.watchQuery<DivisionListResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }
}
