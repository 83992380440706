import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthRefreshTokenInterface } from './definitions/auth-refresh-token.interface';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../problems/problem.service';

@Injectable()
export class AuthRefreshTokenService {

  private apiUrl: string = environment.rest.apiV2Url;

  constructor(private client: HttpClient) {}

  public refreshToken(refreshToken: string): Observable<AuthRefreshTokenInterface> {
    const url = `${this.apiUrl}/auth/refresh-token`;

    return this.client.post<AuthRefreshTokenInterface>(url, { token: refreshToken }).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
