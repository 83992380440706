import { authUserTypeDef } from './auth-user-type-def';

export const authTypeDef = `
  token
  tokenType
  expiresAt
  expiresIn
  user {
    ${authUserTypeDef}
  }
`;
