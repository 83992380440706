import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface ApproveAllFileGroupsResponse {
  task: {
    approveAllFileGroupsByType: {
      output: {
        success;
      };
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class ApproveAllFileGroupsService {
  constructor(private apollo: Apollo) {}

  approveByType(
    taskId: string,
    type: string,
    mutation: DocumentNode
  ): Observable<FetchResult<ApproveAllFileGroupsResponse>> {
    return this.apollo.mutate<ApproveAllFileGroupsResponse>({
      mutation: mutation,
      variables: {
        taskId,
        type,
      },
    });
  }
}
