import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { CarTirePositionInterface } from './definitions/car-tire-position-interface';
import { ListServiceBase } from '../../../classes/list-service-base';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface CarTirePositionListResponse {
  carTirePosition: {
    list: {
      output: Array<CarTirePositionInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CarTirePositionListService extends ListServiceBase {
  constructor(private apollo: Apollo) {
    super();
  }

  getQuery(variables, querySource: DocumentNode): QueryRef<any> {
    return this.apollo.watchQuery<CarTirePositionListResponse>({
      query: querySource,
      fetchPolicy: 'network-only',
    });
  }

  getListQuery(variables, querySource: DocumentNode) {
    return this.apollo.query<CarTirePositionListResponse>({
      query: querySource,
      fetchPolicy: 'network-only',
    });
  }
}
