import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { TaskServiceInterface } from './definitions/task-service-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface TaskByIdResponse {
  task: {
    byId: {
      output: TaskServiceInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TaskByIdService {
  private queryRef: QueryRef<TaskByIdResponse>;
  private pollInterval: number = 15000;
  constructor(private apollo: Apollo) {}

  getTaskById(taskId, query: DocumentNode): QueryRef<TaskByIdResponse> {
    this.queryRef = this.apollo.watchQuery<TaskByIdResponse>({
      query: query,
      variables: {
        id: taskId,
      },
      fetchPolicy: 'network-only',
    });

    return this.queryRef;
  }

  togglePolling(isPolling: boolean) {
    if (this.queryRef) {
      if (isPolling) {
        this.queryRef.refetch();
        this.queryRef.startPolling(this.pollInterval);
      } else {
        this.queryRef.stopPolling();
      }
    }
  }
}
