import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { CarPartTypeInterface } from './definitions/car-part-type-interface';

interface WithIdentificationResponse {
    carPartType: {
        withIdentification: {
            output: CarPartTypeInterface[];
            errors: ErrorsInterface;
        };
  };
}

@Injectable()
export class WithIdentificationService {
  constructor(private apollo: Apollo) {}

  getCarPartTypesWithIdentification(
    querySource: DocumentNode
  ) {
    return this.apollo.query<WithIdentificationResponse>({
      query: querySource,
      fetchPolicy: 'network-only',
    });
  }
}