import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PartnerFileInterface } from './definitions/partner-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface PartnerFileUploadResponse {
  partnerFile: {
    upload: {
      output: PartnerFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class UploadPartnerFileService {
  constructor(private apollo: Apollo) {}

  uploadFile(
    partnerId: string,
    kind: string,
    name: string,
    content: string,
    extension: string,
    mutation: DocumentNode
  ): Observable<FetchResult<PartnerFileUploadResponse>> {
    return this.apollo.mutate<PartnerFileUploadResponse>({
      mutation: mutation,
      variables: {
        partnerId: partnerId,
        kind: kind,
        name: name,
        content: content,
        extension: extension,
      },
    });
  }
}
