import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadPartnerFileService } from './partner-task-file.service';
import { RemovePartnerFileService } from './remove-partner-file.service';

@NgModule({
  imports: [CommonModule],
  providers: [UploadPartnerFileService, RemovePartnerFileService],
})
export class PartnerFileModule {}
