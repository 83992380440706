import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { InsuranceCompanyInterface } from '../tasks/definitions/insurance-company-interface';

interface ListResponse {
  insuranceCompany: {
    listOnlineTaskInspection: {
      output: Array<InsuranceCompanyInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class InsuranceCompanyGetService {
  constructor(private apollo: Apollo) {}
}
