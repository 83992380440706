import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface DivisionByIdResponse {
  division: {
    byId: {
      output: any;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class DivisionByIdService {
  constructor(private apollo: Apollo) {}

  getDivisionById(
    id: string,
    query: DocumentNode
  ): QueryRef<DivisionByIdResponse> {
    return this.apollo.watchQuery<DivisionByIdResponse>({
      query: query,
      variables: {
        id: id,
      },
      fetchPolicy: 'network-only',
    });
  }
}
