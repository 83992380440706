import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DivisionInterface } from './definitions/division-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface DivisionCreateResponse {
  division: {
    create: {
      output: Array<DivisionInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class DivisionCreateService {
  constructor(private apollo: Apollo) {}

  createDivision(
    name: string,
    damageCategoryId: string,
    damageTypeId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<DivisionCreateResponse>> {
    return this.apollo.mutate<DivisionCreateResponse>({
      mutation: mutation,
      variables: {
        name: name,
        damageCategoryId: damageCategoryId,
        damageTypeId: damageTypeId,
      },
    });
  }
}
