import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PartnerInterface } from './definitions/partner-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface PartnerCreateResponse {
  partner: {
    create: {
      output: PartnerInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class PartnerCreateService {
  constructor(private apollo: Apollo) {}

  create(
    name: string,
    code: string,
    type: string,
    location: string,
    contactEmail: string,
    defaultAllowOnlineTaskInspection: boolean,
    allowedOnlineTaskInspection: boolean,
    mutation: DocumentNode
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<PartnerCreateResponse>({
      mutation: mutation,
      variables: {
        name: name,
        type: type,
        code: code,
        location: location,
        contactEmail: contactEmail,
        defaultAllowOnlineTaskInspection: defaultAllowOnlineTaskInspection,
        allowedOnlineTaskInspection: allowedOnlineTaskInspection,
      },
    });
  }
}
