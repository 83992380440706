import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DivisionsService } from '../../divisions/divisions.service';
import { DivisionCreateService } from './create.service';
import { DivisionUpdateService } from './update.service';
import { DivisionByIdService } from './division-by-id.service';
import { AddPartnerToDivisionService } from './add-partner-to-division.service';
import { AddPartnerToEmployeeService } from './add-partner-to-employee.service';
import { RemovePartnerFromEmployeeService } from './remove-partner-from-employee.service';
import { AddEmployeesToDivisionService } from './add-employees-to-division.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    AddPartnerToDivisionService,
    AddEmployeesToDivisionService,
    DivisionsService,
    DivisionCreateService,
    AddPartnerToEmployeeService,
    RemovePartnerFromEmployeeService,
    DivisionByIdService,
    DivisionUpdateService,
  ],
})
export class DivisionsModule {}
