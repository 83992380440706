import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ComponentsGalleryGuard implements CanActivate {
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return environment.enableComponentGallery;
  }
}
