import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskReserveCreateService } from './task-reserve-create/task-reserve-create.service';
import { TaskReserveActualDetailService } from './task-reserve-actual-detail/task-reserve-actual-detail.service';
import { TaskReservesListService } from './task-reserves-list/task-reserves-list.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    TaskReserveActualDetailService,
    TaskReserveCreateService,
    TaskReservesListService,
  ],
})
export class TaskReserveModule {}
