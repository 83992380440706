export const constants = {
  TASK_STATE_DRAFT: 'draft',
  TASK_STATE_NEW: 'new',
  TASK_STATE_INFORMATION_GATHERING: 'informationGathering',
  TASK_STATE_DOCUMENTS_MISSING: 'documentsMissing',
  TASK_STATE_CALCULATION_REQUIRED: 'calculationRequired',
  TASK_STATE_CALCULATED: 'calculated',
  TASK_STATE_REVISION: 'revision',
  TASK_STATE_REVISED: 'revised',
  TASK_STATE_COMPLETED: 'completed',
  TASK_STATE_INVOICED: 'invoiced',
  TASK_STATE_APPROVED: 'approved',
  TASK_STATE_NOT_EXECUTED: 'notExecuted',
  TASK_STATE_CANCELED: 'canceled',
  TASK_TYPE_CALCULATION: 'calculation',
  TASK_TYPE_PRE_CALCULATION: 'precalculation',
  EMPLOYEE_ROLE_ADMIN: 'admin',
  EMPLOYEE_ROLE_ADJUSTER: 'adjuster',
  EMPLOYEE_ROLE_CHIEF: 'chief',
  EMPLOYEE_ROLE_EMPLOYEE: 'employee',
  EMPLOYEE_ROLE_MANAGER: 'division_manager',
  EMPLOYEE_ROLE_REVISER: 'reviser',
  THUMBNAIL_TYPE_SMALL: 'small',
  PARTNER_FILE_TYPE_LOGO: 'logo',
  THUMBNAIL_TYPE_MINI: 'mini',
  TASK_FILE_GROUP_SOURCE_MANUAL: 'manual',
  TASK_FILE_GROUP_SOURCE_AUTOMATIC: 'automatic',
  TASK_FILE_GROUP_TYPE_SOURCE_MANUAL: 'manual',
  TASK_FILE_GROUP_TYPE_SOURCE_SYSTEM: 'automatic',
  TASK_FILE_GROUP_TYPE_IMAGE: 'image',
  TASK_FILE_GROUP_TYPE_DOCUMENT: 'document',
  TASK_FILE_GROUP_STATE_APPROVED: 'approved',
  TASK_FILE_GROUP_STATE_MISSING: 'missing',
  ERROR_INVALID_CREDENTIALS: 'invalidCredentials',
  ERROR_ALREADY_ASSIGNED: 'alreadyAssigned',
  ERROR_ALREADY_ASSIGNED_FULL: 'Task has been already assigned.',
  FORCE_DOWNLOAD_PARAM_KEY: 'd',
  ORDER_BY_TASK_CREATED_AT: 'taskCreatedAt',
  ORDER_BY_TASK_STATE_ORDER: 'taskStateOrder',
  MAX_UPLOAD_FILE_SIZES: {
    ATTACHMENT: 50 * 1024 * 1024,
    CALCULATION: 50 * 1024 * 1024,
    PROFILE_PHOTO: 20 * 1024 * 1024,
    PARTNER_PHOTO: 20 * 1024 * 1024,
  },
  TOKEN_TYPE_CLIENT_SMS_UPLOAD: 'client_sms_upload',
  TOKEN_TYPE_PASSWORD_RESET: 'password_reset',
  TOKEN_TYPE_CLIENT_ONLINE_TASK_INSPECTION: 'client_online_task_inspection',
  LICENSE_PLATE_REGEX: new RegExp("^(?!.*(?:Q|W|O|G))[A-Za-z0-9]+$")
};

export const partyTypesConstants = {
  INDIVIDUAL: 'individual',
  LEGAL_ENTITY: 'legalEntity',
};
