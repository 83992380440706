import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { UploadProfileImageResponseInterface } from './definitions/upload-profile-image-response-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class UploadProfileImageService {
  constructor(private apollo: Apollo) {}

  uploadProfileImage(
    content: string,
    extension: string,
    mutation: DocumentNode
  ): Observable<FetchResult<UploadProfileImageResponseInterface>> {
    return this.apollo.mutate<UploadProfileImageResponseInterface>({
      mutation: mutation,
      variables: {
        content: content,
        extension: extension,
      },
    });
  }
}
