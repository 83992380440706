import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DivisionInterface } from './definitions/division-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface DivisionUpdateResponse {
  division: {
    update: {
      output: DivisionInterface;
      errors: ErrorsInterface;
    };
  };
}

export interface DivisionUpdatePatch {
  name?: string;
  damageCategoryId?: string;
  damageTypeId?: string;
}

@Injectable()
export class DivisionUpdateService {
  constructor(private apollo: Apollo) {}

  updateDivision(
    id: string,
    patch: DivisionUpdatePatch,
    mutation: DocumentNode
  ): Observable<FetchResult<DivisionUpdateResponse>> {
    return this.apollo.mutate<DivisionUpdateResponse>({
      mutation: mutation,
      variables: {
        id: id,
        name: patch.name,
        damageCategoryId: patch.damageCategoryId,
        damageTypeId: patch.damageTypeId,
      },
    });
  }
}
