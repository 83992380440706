import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface CalculationFileRemoveResponse {
  taskCalculationFile: {
    remove: {
      output: {
        id;
      };
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class RemoveTaskCalculationFileService {
  constructor(private apollo: Apollo) {}

  removeCalculation(
    calculationFileId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<CalculationFileRemoveResponse>> {
    return this.apollo.mutate<CalculationFileRemoveResponse>({
      mutation: mutation,
      variables: {
        id: calculationFileId,
      },
    });
  }
}
