import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { TaskFileGroupInterface } from './definitions/task-file-group-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface ResetTaskFileGroupResponse {
  taskFileGroup: {
    reset: {
      output: TaskFileGroupInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class ResetTaskFileGroupService {
  constructor(private apollo: Apollo) {}

  reset(
    taskFileGroupId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<ResetTaskFileGroupResponse>> {
    return this.apollo.mutate<ResetTaskFileGroupResponse>({
      mutation: mutation,
      variables: {
        id: taskFileGroupId,
      },
    });
  }
}
