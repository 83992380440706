import { Injectable } from '@angular/core';
import { InfoService } from '../graphql/auth/info.service';
import { AuthInterface } from '../graphql/auth/definitions/auth-interface';
import { authClearApiV2Token, authClearRefreshToken, authClearToken } from '../../utils';
import { AuthorizeService } from '../graphql/auth/authorize.service';
import { LogoutService } from '../graphql/auth/logout.service';
import { LoggerService } from '../logger.service';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

@Injectable()
export class AuthService {
  isUserLogged = () => this._info !== null;
  private _info: AuthInterface = null;

  constructor(
    private apollo: Apollo,
    private authorizeService: AuthorizeService,
    private logger: LoggerService,
    private logoutService: LogoutService,
    private infoService: InfoService
  ) {}

  public async authorize(username: string, password: string): Promise<any> {
    return this.authorizeService
      .authorize(username, password)
      .pipe(
        map((authData) => {
          return this._mapAuth(authData.data.auth.authorizeEmployee);
        })
      )
      .toPromise();
  }

  public async logout(): Promise<boolean> {
    this._info = null;
    try {
      await this.logoutService.logout().toPromise();
    } catch (error) {
      this.logger.error('Logout failed', error);
    }

    authClearToken();
    authClearApiV2Token();
    authClearRefreshToken();

    await this.clearStore();

    return true;
  }

  async clearStore() {
    await this.apollo.client.clearStore();
  }

  async getInfo() {
    if (this._info != null) {
      return this._info;
    }
    try {
      const authUserResponse = await this.infoService.getInfo().toPromise();
      const authUser = authUserResponse.data.auth.byMe;
      if (!!authUser.output) {
        return authUser.output;
      } else {
        return null;
      }
    } catch (error) {
      this.logger.error('Getting auth user info failed', error);
    }
    return null;
  }

  private _mapAuth(authData): AuthInterface {
    const auth = { ...authData };
    if (!!authData.output) {
      auth.output.expiresAt = new Date(auth.output.expiresAt);
    }
    return auth;
  }
}
