import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface EmployeeResetPasswordResponse {
  employee: {
    resetPassword: {
      output: { id: string };
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class SetNewPasswordService {
  constructor(private apollo: Apollo) {}

  setNewPassword(
    token: string,
    newPassword: string,
    mutation: DocumentNode
  ): Observable<FetchResult<EmployeeResetPasswordResponse>> {
    return this.apollo.mutate<EmployeeResetPasswordResponse>({
      mutation: mutation,
      variables: {
        token: token,
        newPassword: newPassword,
      },
    });
  }
}
