import { Injectable } from '@angular/core';
import { AuthInterface } from './definitions/auth-interface';
import authorizeMutation from './mutations/authorize';
import { ErrorsInterface } from '../errors/errors-interface';
import { Apollo } from 'apollo-angular';

interface AuthorizeResponse {
  auth: {
    authorizeEmployee: {
      output: AuthInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class AuthorizeService {
  constructor(private apollo: Apollo) {}

  authorize(username: string, password: string) {
    return this.apollo.mutate<AuthorizeResponse>({
      mutation: authorizeMutation,
      variables: {
        username,
        password,
      },
    });
  }
}
