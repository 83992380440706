import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskServiceInterface } from './definitions/task-service-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface MarkAsRevisedResponse {
  task: {
    markAsRevised: {
      output: TaskServiceInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class MarkAsRevisedService {
  constructor(private apollo: Apollo) {}

  markAsRevised(
    taskId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<MarkAsRevisedResponse>> {
    return this.apollo.mutate<MarkAsRevisedResponse>({
      mutation: mutation,
      variables: {
        taskId,
      },
    });
  }
}
