import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthApiV2Service } from './auth-api-v2.service';
import { LoggerService } from '../logger.service';
import { RestModule } from '../rest/rest.module';

@NgModule({
  imports: [CommonModule, RestModule],
  providers: [
    AuthApiV2Service,
    LoggerService
  ],
})
export class AuthApiV2Module {}
