import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerListService } from './partner-list.service';
import { PartnerByIdService } from './partner-by-id.service';
import { PartnerUpdateService } from './partner-update.service';
import { PartnerCreateService } from './partner-create.service';
import { PartnerUserCreateService } from './partner-user-create.service';
import { PartnerUserUpdateService } from './partner-user-update.service';
import { PartnerUserSendChangePasswordService } from './partner-user-send-change-password.service';
import {
  PartnerCustomTaskCompletedEmailingSubjectService
} from './partner-custom-task-completed-emailing-subject.service';
import {
  PartnerDisableCustomTaskCompletedEmailingSubjectService
} from './partner-disable-custom-task-completed-emailing-subject.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    PartnerListService,
    PartnerUserCreateService,
    PartnerUserUpdateService,
    PartnerUserSendChangePasswordService,
    PartnerCreateService,
    PartnerUpdateService,
    PartnerByIdService,
    PartnerCustomTaskCompletedEmailingSubjectService,
    PartnerDisableCustomTaskCompletedEmailingSubjectService,
  ],
})
export class PartnersModule {}
