import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import logoutMutation from './mutations/logout';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';

interface LogoutResponse {
  auth: {
    logout: {
      output: boolean;
    };
  };
}

@Injectable()
export class LogoutService {
  constructor(private apollo: Apollo) {}

  logout(): Observable<FetchResult<LogoutResponse>> {
    return this.apollo.mutate<LogoutResponse>({
      mutation: logoutMutation,
    });
  }
}
