import { Injectable } from '@angular/core';
import { EmployeeInterface } from './definitions/employee-interface';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface EmployeesUpdateResponse {
  employee: EmployeeInterface;
}

export interface EmployeesUpdatePatch {
  email?: string;
  name?: string;
  roles?: [string];
  divisions?: [string];
}

@Injectable()
export class EmployeesUpdateService {
  constructor(private apollo: Apollo) {}

  update(
    id: string,
    patch: EmployeesUpdatePatch,
    mutation: DocumentNode
  ): Observable<FetchResult<EmployeesUpdateResponse>> {
    return this.apollo.mutate<EmployeesUpdateResponse>({
      mutation: mutation,
      variables: {
        id: id,
        email: patch.email,
        roles: patch.roles,
        name: patch.name,
        divisions: patch.divisions,
      },
    });
  }
}
