import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskFileInterface } from './definitions/task-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

export interface SetDefaultTaskFileResponse {
  taskFile: {
    setDefault: {
      output: TaskFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class SetDefaultTaskFileService {
  constructor(private apollo: Apollo) {}

  setDefault(
    taskFileId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<SetDefaultTaskFileResponse>> {
    return this.apollo.mutate<SetDefaultTaskFileResponse>({
      mutation: mutation,
      variables: {
        id: taskFileId,
      },
    });
  }
}
