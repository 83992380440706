import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { CarPartTypeInterface } from './definitions/car-part-type-interface';

interface CarPartTypeByIdResponse {
  carPartType: {
    byId: {
      output: CarPartTypeInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CarPartTypeByIdService {
  constructor(private apollo: Apollo) {}

  getCarPartTypeById(carPartTypeId: string, querySource: DocumentNode) {
    return this.apollo.query<CarPartTypeByIdResponse>({
      query: querySource,
      variables: {
        id: carPartTypeId,
      },
      fetchPolicy: 'network-only',
    });
  }
}
