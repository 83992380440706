import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TagInterface } from './definitions/tag-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface TagActivateResponse {
  Tag: {
    create: {
      output: Array<TagInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TagActivateService {
  constructor(private apollo: Apollo) {}

  activateTag(
    tag: TagInterface,
    mutation: DocumentNode
  ): Observable<FetchResult<TagActivateResponse>> {
    return this.apollo.mutate<TagActivateResponse>({
      mutation: mutation,
      variables: {
        id: tag.id
      },
    });
  }
}
