import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PartnerFileInterface } from './definitions/partner-file-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface RemovePartnerFileResponse {
  partnerFile: {
    remove: {
      output: PartnerFileInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class RemovePartnerFileService {
  constructor(private apollo: Apollo) {}

  removePartnerFile(
    partnerFileId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<RemovePartnerFileResponse>> {
    return this.apollo.mutate<RemovePartnerFileResponse>({
      mutation: mutation,
      variables: {
        partnerFileId: partnerFileId,
      },
    });
  }
}
