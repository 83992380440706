import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskServiceInterface } from './definitions/task-service-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface AssumeRevisionResponse {
  task: {
    assumeRevision: {
      output: TaskServiceInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class AssumeRevisionService {
  constructor(private apollo: Apollo) {}

  assumeRevision(
    taskId,
    mutation: DocumentNode
  ): Observable<FetchResult<AssumeRevisionResponse>> {
    return this.apollo.mutate<AssumeRevisionResponse>({
      mutation: mutation,
      variables: {
        taskId,
      },
    });
  }
}
