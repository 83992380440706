import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { EnumerationInterface } from './definitions/enumeration-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface EnumerationResponse {
  enumeration: {
    output: EnumerationInterface;
    errors: ErrorsInterface;
  };
}

@Injectable()
export class EnumerationGetService {
  constructor(private apollo: Apollo) {}

  getEnumerations(query: DocumentNode): QueryRef<EnumerationResponse> {
    return this.apollo.watchQuery<EnumerationResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }

  getEnumerationsQuery(query: DocumentNode) {
    return this.apollo.query<EnumerationResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }
}
