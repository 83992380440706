import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizeService } from './authorize.service';
import { AuthRefreshTokenService } from './auth-refresh-token.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthorizeService,
    AuthRefreshTokenService,
  ],
})
export class AuthApiV2Module {}
