import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarPartTypeByIdService } from './by-id.service';
import { CarPartTypeByIdentificationService } from './by-identification.service';
import { CarPartTypeCreateService } from './create.service';
import { WithIdentificationService } from './with-identification.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    CarPartTypeByIdService,
    CarPartTypeByIdentificationService,
    CarPartTypeCreateService,
    WithIdentificationService
  ],
})
export class CarPartTypesModule {}
