import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { PartnerInterface } from './definitions/partner-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface PartnerListResponse {
  partner: {
    byId: {
      output: PartnerInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class PartnerByIdService {
  constructor(private apollo: Apollo) {}

  byId(id: string, query: DocumentNode): QueryRef<any> {
    return this.apollo.watchQuery<PartnerListResponse>({
      query: query,
      variables: {
        id: id,
      },
      fetchPolicy: 'network-only',
    });
  }
}
