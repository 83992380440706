import { EmployeeInterface } from './definitions/employee-interface';
import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';

interface EmployeeListResponse {
  employee: {
    list: {
      output: Array<EmployeeInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class EmployeeListService {
  constructor(private apollo: Apollo) {}

  getEmployees(query: DocumentNode): QueryRef<EmployeeListResponse> {
    return this.apollo.watchQuery<EmployeeListResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }
}
