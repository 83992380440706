import { gql } from 'apollo-angular';
import { authUserTypeDef } from '../definitions/auth-user-type-def';
import { errorsTypeDef } from '../../errors/errors-type-def';

const infoQuery = gql`
  query AuthorizeByMe{
    auth {
      byMe {
        output {
          ${authUserTypeDef}
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;

export default infoQuery;
