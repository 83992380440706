import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizeService } from './authorize.service';
import { LogoutService } from './logout.service';
import { InfoService } from './info.service';
import { AuthService } from '../../auth/auth.service';

@NgModule({
  imports: [CommonModule],
  providers: [AuthService, AuthorizeService, LogoutService, InfoService],
})
export class AuthModule {}
