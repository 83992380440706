import { EmployeeInterface } from './definitions/employee-interface';
import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';

interface EmployeeByMeResponse {
  employee: {
    byMe: {
      output: EmployeeInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class EmployeeByMeService {
  constructor(private apollo: Apollo) {}

  getByMe(query): QueryRef<EmployeeByMeResponse> {
    return this.apollo.watchQuery<EmployeeByMeResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }
}
