import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { CarPartTypeInterface } from './definitions/car-part-type-interface';

interface CarPartTypeByIdentificationResponse {
  carPartType: {
    byIdentification: {
      output: CarPartTypeInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CarPartTypeByIdentificationService {
  constructor(private apollo: Apollo) {}

  getCarPartTypeByIdentification(
    carPartTypeIdentification: string,
    querySource: DocumentNode
  ) {
    return this.apollo.query<CarPartTypeByIdentificationResponse>({
      query: querySource,
      variables: {
        identification: carPartTypeIdentification,
      },
      fetchPolicy: 'network-only',
    });
  }
}
