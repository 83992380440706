import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { TagInterface } from './definitions/tag-interface';

interface GetAllTaskTagsResponse {
  taskTag: {
    list: {
      output: Array<TagInterface>;
      errors: ErrorsInterface;
    };
  };
}

interface GetAllInactiveTaskTagsResponse {
  taskTag: {
    inactiveList: {
      output: Array<TagInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class GetTaskTagsService {
  constructor(private apollo: Apollo) {}

  getAll(query: DocumentNode): QueryRef<GetAllTaskTagsResponse> {
    return this.apollo.watchQuery<GetAllTaskTagsResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }

  getInactive(query: DocumentNode): QueryRef<GetAllInactiveTaskTagsResponse> {
    return this.apollo.watchQuery<GetAllInactiveTaskTagsResponse>({
      query: query,
      fetchPolicy: 'network-only',
    });
  }
}
