import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { handleProblem } from '../../problems/problem.service';
import { CentralEventDetailInterface } from '../definitions/central-event-detail-interface';

@Injectable()
export class CentralEventDetailByIdService {

  private apiUrl = environment.rest.apiV2Url;

  public constructor(private client: HttpClient) {}

  public detail(id: string): Observable<CentralEventDetailInterface> {
    const url = `${this.apiUrl}/central-events/${id}`;

    return this.client.get<CentralEventDetailInterface>(url).pipe(
      catchError(error => throwError(handleProblem(error)))
    );
  }
}
