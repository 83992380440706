import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadTaskCalculationFileService } from './upload-task-calculation-file.service';
import { RemoveTaskCalculationFileService } from './remove-calculation-file.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    UploadTaskCalculationFileService,
    RemoveTaskCalculationFileService,
  ],
})
export class TaskCalculationFileModule {}
