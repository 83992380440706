import { gql } from 'apollo-angular';
import { errorsTypeDef } from '../../errors/errors-type-def';

const logoutMutation = gql`
  mutation {
    auth {
      logout {
        output
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;

export default logoutMutation;
