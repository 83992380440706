import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { TaskServiceInterface } from './definitions/task-service-interface';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

export interface DocumentationCompletedResponse {
  task: {
    documentationCompleted: {
      output: TaskServiceInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class TaskDocumentationCompletedService {
  constructor(private apollo: Apollo) {}

  public documentationCompleted(
    taskId,
    mutation: DocumentNode
  ): Observable<FetchResult<DocumentationCompletedResponse>> {
    return this.apollo.mutate<DocumentationCompletedResponse>({
      mutation: mutation,
      variables: {
        id: taskId,
      },
    });
  }
}
