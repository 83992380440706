import { Injectable } from '@angular/core';
import { EmployeeInterface } from './definitions/employee-interface';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface EmployeesRemoveResponse {
  employee: EmployeeInterface;
}

@Injectable()
export class EmployeesRemoveService {
  constructor(private apollo: Apollo) {}

  remove(
    id: string,
    mutation: DocumentNode
  ): Observable<FetchResult<EmployeesRemoveResponse>> {
    return this.apollo.mutate<EmployeesRemoveResponse>({
      mutation: mutation,
      variables: {
        id: id,
      },
    });
  }
}
