import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesCreateService } from './employee-create.service';
import { EmployeeListService } from './employee-list.service';
import { EmployeeByMeService } from './employee-be-me.service';
import { EmployeesRemoveService } from './employee-remove.service';
import { EmployeesUpdateService } from './employee-update.service';
import { EmployeeSendChangePasswordService } from './employee-send-change-password.service';
import { SetNewPasswordService } from './set-new-password.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    EmployeeListService,
    EmployeeSendChangePasswordService,
    EmployeesRemoveService,
    EmployeesUpdateService,
    EmployeesCreateService,
    EmployeeByMeService,
    SetNewPasswordService,
  ],
})
export class EmployeesModule {}
