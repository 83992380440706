import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetTaskTagsService } from './get-task-tags.service';
import { TagActivateService } from './activate-service';
import { TagCreateService } from './create.service';
import { TagRemoveService } from './remove-service';
import { TagUpdateService } from './update-service';

@NgModule({
  imports: [CommonModule],
  providers: [
    GetTaskTagsService,
    TagActivateService,
    TagCreateService,
    TagRemoveService,
    TagUpdateService
  ],
})
export class TaskTagModule {}
