import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';

class Resolver {
  private subscriptionForQuery: any;
  private subscriptionForModified: any;
  private subscriber: any;

  constructor(
    private apollo: Apollo,
    private variables: any,
    private listQuery: any,
    private subscriptionQuery: any,
    private applyCallback: any
  ) {}

  getResult() {
    const that = this;
    return new Observable((subscriber) => {
      that.subscriber = subscriber;
      that.subscribe();
      return () => {
        that.unsubscribe();
      };
    });
  }

  private subscribe() {
    const options: any = {
      query: this.listQuery,
      fetchPolicy: 'network-only',
      pollInterval: 15000,
    };
    if (this.variables) {
      options.variables = this.variables;
    }
    const resultQuery = this.apollo.watchQuery(options);
    this.subscriptionForQuery = resultQuery.valueChanges.subscribe((data) => {
      this.subscriber.next(data);
    });

    // const optionsSubcription: any = {
    //   query: this.subscriptionQuery,
    // };
    // if (this.variables) {
    //   optionsSubcription.variables = this.variables;
    // }
    // const resultModified = this.apollo.subscribe(optionsSubcription);
    // this.subscriptionForModified = resultModified.subscribe(data => {
    //   const out = this.applyCallback(data);
    //   this.subscriber.next({
    //     data: out,
    //     loading: false,
    //   });
    // });
  }

  private unsubscribe() {
    this.subscriptionForQuery.unsubscribe();
    // this.subscriptionForModified.unsubscribe();
  }
}

export abstract class FilteredListServiceBase<T> {
  constructor(public apollo: Apollo) {}

  getList(
    variables,
    querySource?: DocumentNode
  ): Observable<ApolloQueryResult<T>> {
    const resolver = new Resolver(
      this.apollo,
      variables,
      this.getListQuery(querySource),
      this.getMutationQuery(),
      this.applyNewData
    );
    return resolver.getResult() as Observable<ApolloQueryResult<T>>;
  }

  abstract getListQuery(querySource?: DocumentNode);

  abstract getMutationQuery();

  abstract applyNewData(newResult);
}
