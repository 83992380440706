import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ErrorsInterface } from '../errors/errors-interface';
import { TaskFileGroupInterface } from './definitions/task-file-group-interface';
import { taskDetailQuery } from '../../../../admin/task-detail/task-detail.data';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';
import { cloneDeep } from '@apollo/client/utilities';

interface CreateTaskFileGroupResponse {
  taskFileGroup: {
    create: {
      output: TaskFileGroupInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CreateTaskFileGroupService {
  constructor(private apollo: Apollo) {}

  create(
    taskId: string,
    name: string,
    type: string,
    mutation: DocumentNode
  ): Observable<FetchResult<CreateTaskFileGroupResponse>> {
    return this.apollo.mutate<CreateTaskFileGroupResponse>({
      mutation: mutation,
      variables: {
        taskId: taskId,
        name: name,
        type: type,
      },
      update: (proxy, mutationResult) => {
        const data = <{ task: any }>(
          proxy.readQuery({ query: taskDetailQuery, variables: { id: taskId } })
        );

        if (data === null) {
          return;
        }

        if (
          mutationResult.data.taskFileGroup.create.output === undefined
          || mutationResult.data.taskFileGroup.create.output === null
        ) {
          return;
        }

        const clonedCacheData = cloneDeep(data);
        clonedCacheData.task.byId.output.taskFileGroups.push(mutationResult.data.taskFileGroup.create.output);

        proxy.writeQuery({
          query: taskDetailQuery,
          variables: { id: taskId },
          data: clonedCacheData,
        });
      },
    });
  }
}
