import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChangePasswordService } from './change-password.service';
import { UpdateMeService } from './update-me.service';
import { RemoveProfileImageService } from './remove-profile-image.service';
import { UploadProfileImageService } from './upload-profile-image.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    ChangePasswordService,
    UpdateMeService,
    RemoveProfileImageService,
    UploadProfileImageService,
  ],
})
export class ProfileModule {}
