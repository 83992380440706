import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { PartnerTagInterface } from './definitions/partner-tag-interface';

interface GetAllPartnerTagsResponse {
  partnerTag: {
    list: {
      output: Array<PartnerTagInterface>;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class GetPartnerTagsService {
  constructor(private apollo: Apollo) {}

  getAll(query: DocumentNode): QueryRef<GetAllPartnerTagsResponse> {
    return this.apollo.watchQuery<GetAllPartnerTagsResponse>({
      query: query,
      pollInterval: 15000,
      fetchPolicy: 'network-only',
    });
  }
}
