import { Injectable } from '@angular/core';
import { AuthInterface } from '../rest/auth/definitions/auth-interface';
import { AuthorizeService } from '../rest/auth/authorize.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthErrorInterface } from '../rest/auth/definitions/auth-error-interface';
import { AuthRefreshTokenService } from '../rest/auth/auth-refresh-token.service';
import { AuthRefreshTokenInterface } from '../rest/auth/definitions/auth-refresh-token.interface';

@Injectable()
export class AuthApiV2Service {
  constructor(
    private authorizeService: AuthorizeService,
    private authRefreshTokenService: AuthRefreshTokenService,
  ) {}

  public authorize(username: string, password: string): Promise<AuthInterface | AuthErrorInterface> {
    return this.authorizeService
      .authorize(username, password)
      .pipe(
        map((authData) => {
          return this._mapAuth(authData);
        }),
        catchError((error) => {
          return of({ success: false, error: error });
        })
      )
      .toPromise();
  }

  public refreshToken(refreshToken: string): Observable<AuthRefreshTokenInterface> {
    return this.authRefreshTokenService.refreshToken(refreshToken)
      .pipe(
        map((authData: AuthRefreshTokenInterface) => {
          return this._mapAuthRefreshTokenInterface(authData);
        })
      );
  }

  private _mapAuth(authData): AuthInterface {
    const auth = { ...authData };

    if (!!authData) {
      auth.tokenValidTo = new Date(auth.tokenValidTo);
      auth.refreshTokenValidTo = new Date(auth.refreshTokenValidTo);
    }

    return auth;
  }

  private _mapAuthRefreshTokenInterface(response: AuthRefreshTokenInterface): AuthRefreshTokenInterface {
    response.tokenValidTo = new Date(response.tokenValidTo);
    response.refreshTokenValidTo = new Date(response.refreshTokenValidTo);

    return response;
  }
}
