import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { DocumentNode } from 'graphql';
import { CarPartTypeInterface } from './definitions/car-part-type-interface';
import { Observable } from 'rxjs';
import { FetchResult } from '@apollo/client/core';

interface CreateCarPartTypeResponse {
  carPartType: {
    create: {
      output: CarPartTypeInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class CarPartTypeCreateService {
  constructor(private apollo: Apollo) {}

  create(
    carPartType,
    mutation: DocumentNode
  ): Observable<FetchResult<CreateCarPartTypeResponse>> {
    return this.apollo.mutate<CreateCarPartTypeResponse>({
      mutation: mutation,
      variables: carPartType,
    });
  }
}
