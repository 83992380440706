import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AuthInterface } from './definitions/auth-interface';
import infoQuery from './queries/by-me';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

interface InfoResponse {
  auth: {
    byMe: {
      output: AuthInterface;
    };
  };
}

@Injectable()
export class InfoService {
  constructor(private apollo: Apollo) {}

  getInfo(): Observable<ApolloQueryResult<InfoResponse>> {
    return this.apollo.query<InfoResponse>({
      query: infoQuery,
      fetchPolicy: 'network-only',
    });
  }
}
