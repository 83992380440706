import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ChangePasswordResponseInterface } from './definitions/change-password-response-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

@Injectable()
export class ChangePasswordService {
  constructor(private apollo: Apollo) {}

  changePassword(
    oldPass: string,
    newPass: string,
    mutation: DocumentNode
  ): Observable<FetchResult<ChangePasswordResponseInterface>> {
    return this.apollo.mutate<ChangePasswordResponseInterface>({
      mutation: mutation,
      variables: {
        oldPass: oldPass,
        newPass: newPass,
      },
    });
  }
}
