import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DivisionInterface } from './definitions/division-interface';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface AddPartnerToDivisionResponse {
  division: {
    addPartnerToDivision: {
      output: DivisionInterface;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class AddPartnerToDivisionService {
  constructor(private apollo: Apollo) {}

  addPartnerToDivision(
    partnerId: string,
    divisionId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<AddPartnerToDivisionResponse>> {
    return this.apollo.mutate<AddPartnerToDivisionResponse>({
      mutation: mutation,
      variables: {
        partnerId: partnerId,
        divisionId: divisionId,
      },
    });
  }
}
