import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ErrorsInterface } from '../errors/errors-interface';
import { Observable } from 'rxjs';
import { PartnerUser } from './definitions/partner-user-interface';
import { DocumentNode } from 'graphql';
import { FetchResult } from '@apollo/client/core';

interface PartnerUserCreateResponse {
  partnerUser: {
    sendChangePassword: {
      output: PartnerUser;
      errors: ErrorsInterface;
    };
  };
}

@Injectable()
export class PartnerUserSendChangePasswordService {
  constructor(private apollo: Apollo) {}

  sendChangePassword(
    userId: string,
    mutation: DocumentNode
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<PartnerUserCreateResponse>({
      mutation: mutation,
      variables: {
        id: userId,
      },
    });
  }
}
